import React, { useState, useRef, useEffect } from "react"
import Col, { ColProps } from "../Col/Col";
import Row, { RowProps } from "../Row/Row";
import "./ReviewsMasterminds.scss"
import Review from "../Review/Review";
import Title from '@/components/Title/Title';
import Button from "../Button/Button";

import review1 from "../../img/reviews/just.png"
import review2 from "../../img/reviews/marcus.png"
import review3 from "../../img/reviews/mia.png"
import review4 from "../../img/reviews/alessandro.png"
import review5 from "../../img/reviews/oskarr.png"
import review6 from "../../img/reviews/vedank.png"

import arrowRight from "../../img/arrowRight.svg"
import arrowLeft from "../../img/arrowLeft.svg"

interface ReviewsProps {
  className?: string
  id?: string
  name?: string
  text?: string
  image?: string
}

const Reviews = ({
  className,
  id,
  name,
  text,
  image
} : ReviewsProps) => {


return (

    <Row className="reviews__wrap__masterminds">
      <div className="review__row">
        <Review
          id="review__1"
          link="https://player.vimeo.com/video/760267330"
          image={review1}
          className="active"
          name="Just"
          text="I've uncovered things that have been controlling my life, you couldn't even imagine. I am the most critical person when it comes to courses or 'gurus' and I've vetted them for you."
        />
        <Review
          id="review__2"
          link="https://player.vimeo.com/video/760268838"
          image={review2}
          className="active"
          name="Marcus"
          text="The principles they teach, their mindset stuff is the most important thing in business... and in life."
        />
        <Review
          id="review__3"
          link="https://player.vimeo.com/video/760269355"
          image={review3}
          className="active"
          name="Mia"
          text="This is the only program you will ever need to succeed. Don't hesitate, I promise it will transform your life."
        />
      </div>
      <div className="review__row">
        <Review
            id="review__4"
            link="https://player.vimeo.com/video/760256839"
            image={review4}
            className="active"
            name="Alessandro"
            text="I've worked with a bunch of people in the mindset space. I haven't ever met two individuals who take it as seriously. For them it's really about giving value. I'm proud to be part of the community."
        />
        <Review
            id="review__5"
            link="https://player.vimeo.com/video/760270392"
            image={review5}
            className="active"
            name="Oscar"
            text="I'm happy and glad I joined. I made a whole transition mentally. Join the community and be a part of us."
        />
        <Review
            id="review__6"
            link="https://player.vimeo.com/video/760271381"
            image={review6}
            className="active"
            name="Vedank"
            text="I'm so happy I joined, Vash and Jon shared so much value. Best investment  of my whole life."
        />
        </div>
      </Row>

  )
};

export default Reviews;
