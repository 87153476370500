import * as React from "react"
import "../App.scss";
import Section from "../components/Section/Section";
import Row from "../components/Row/Row";
import Col from "../components/Col/Col";
import CtaBar from "../components/CtaBar/CtaBar";
import Nav from "../components/Nav/Nav";
import Footer from "../components/Footer/Footer";
import Button from "../components/Button/Button";
import ReviewsMasterminds from "../components/ReviewsMasterminds/ReviewsMasterminds";
import Reviews from "../components/Reviews/Reviews";
import { InlineWidget } from "react-calendly";

import ImgAchievers from "../img/masterminds.gif"

import Event1 from "../img/event1.jpg"
import Event2 from "../img/event5.jpg"
import Event3 from "../img/event3.jpg"
import Event4 from "../img/event4.jpg"
import Event5 from "../img/event2.jpg"

import Event6 from "../img/mastermind1.jpg"
import Event7 from "../img/mastermind2.jpg"
import Event8 from "../img/mastermind3.jpg"
import Event9 from "../img/mastermind4.jpg"
import Event10 from "../img/mastermind5.jpg"



// data

// markup
const Podcast = () => {
  return (
    <main>
      <Nav />

      <Section className="hero" id="hero">
        <Row>

          <Col size="50" className="hero__text">
            <h1>Mindtrepreneur Masterminds.</h1>
            <p className="p30">For entrepreneurs wanting more from life.</p>
            <Button type="secondary" text="Explore more" link="#achievers" />
          </Col>
          <Col size="50">
            <div className="video-inner" id="mainVideo">
              <iframe className="mainVideo" src="https://player.vimeo.com/video/764498261?autoplay=1&loop=1"/>
            </div>
          </Col>
        </Row>
      </Section>

      <Section className="events">

        <Row>
          <Col size="50"><h2>A community like no other in the entrepreneur space</h2></Col>
        </Row>

        <Row>
          <Col size="40"><img src={Event2} className="events__photo" /></Col>
          <Col size="40"><img src={Event1} className="events__photo" /></Col>
          <Col size="40"><img src={Event4} className="events__photo" /></Col>
        </Row>

        <Row>
          <Col>
            <p className="p30">
              We believe in the power of surrounding yourself with other like
              minded entrepreneurs on the same mission as you. This is exactly
              why we launched the Mindtrepreneur Mastermind, to bring a group of
              entrepreneurs on the mission to not only build a successful business,
              but to also become successful in all areas of their life, their health,
              happiness and relationships.
            </p>
          </Col>
        </Row>

      </Section>

      <Section className="achievers" id="achievers">
        <Row>
          <Col size="60">
            <img src={Event5} alt="Achievers Mindtrepreneur" />
          </Col>

          <Col size="40">
            <div className="achievers__numbers">
              <div className="achievers__numbers__item">
                <h2>1</h2>
                <p>year of organizing Masterminds</p>
              </div>
              <div className="achievers__numbers__item">
                <h2>4</h2>
                <p>Mindtrepreneur Masterminds</p>
              </div>
            </div>
            <div className="achievers__numbers">
              <div className="achievers__numbers__item">
                <h2>60</h2>
                <p>students</p>
              </div>
              <div className="achievers__numbers__item">
                <h2>25</h2>
                <p>nationalities</p>
              </div>
            </div>

            </Col>
        </Row>


      </Section>

      <Section className="description">

        <Row>
          <h2>Are you ready to transfrom not just your business, but your entire life?</h2>
        </Row>

        <Row className="description__row">

          <Col size="100">
              <div className="description__items">
                <p className="p30 ico-lecture">4-day deep dive mastermind events in luxury accommodations located all in the finest locations all around the world</p>
                <p className="p30 ico-community">Join and connect with a community of like minded entrepreneurs to help you on your journey to success</p>
                <p className="p30 ico-one-one">Deep dive 1-1 sessions with Jon & Vash to gain clarity and certainty on how to scale your business and master all other areas of your life</p>
                <p className="p30 ico-growth">In depth masterclass sessions with Q&A’s where we reveal to you the lessons and strategies we have used to scale our businesses and live a life of freedom</p>
                <p className="p30 ico-meditation">Guided meditations &  breathwork sessions to help you discover and overcome the limitations preventing you from reaching your full potential</p>
                <p className="p30 ico-star">Once in a lifetime experiences. There is no other community or mastermind out there for entrepreneurs like the Mindtrepreneur Mastermind</p>
              </div>
          </Col>

        </Row>

      </Section>

      <Section className="reviews reviewsThirds">
        <Row>
          <h2>What people said about the event</h2>
        </Row>
        <Row>
          <ReviewsMasterminds />
        </Row>
      </Section>

      <Section className="events">

        <Row>
          <Col size="40"><img src={Event10} className="events__photo" /></Col>
          <Col size="40"><img src={Event6} className="events__photo" /></Col>
          <Col size="40"><img src={Event7} className="events__photo" /></Col>
        </Row>
        <Row>
          <Col size="40"><img src={Event8} className="events__photo" /></Col>
          <Col size="40"><img src={Event9} className="events__photo" /></Col>
          <Col size="40"><img src={Event4} className="events__photo" /></Col>
        </Row>

      </Section>

      <Section className="calendly">

        <Row>
          <Col size="40">
            <h2>Are you ready to create the best memories and transform your life?</h2>
            <p>
              This event is exclusive and limited to 20 people at a time and for that reason it is by application only. Please apply below for a call to see if you’re a good fit. We are very protective about the energy of people who come to the mastermind, that’s why EVERYBODY needs to go through the application call.
            </p>
          </Col>
          <Col size="60">
            <InlineWidget url="https://calendly.com/mindtrepreneur/mindtrepreneur-mastermind?hide_gdpr_banner=1" />
          </Col>
        </Row>

      </Section>


      <Footer/>

    </main>
  )
}

export default Podcast
